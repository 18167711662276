(function($) {

   // setup menu

  var menu_toggle = $('.menu-toggle');
  var menu = $('#site-navigation');
  
  $(menu_toggle).click(function() {
	  $("#primary-menu").slideToggle();
  });
  
 
 	 

  // setup screen size

  var screen_w = $(window).width();
  var screen_h = $(window).height();

  var hover_timer, focus_timer;

  // Get current menu type

  function is_desktop_menu() {
    screen_w = $(window).width();
    if(screen_w > 974) {
      return true;
    }
    return false;
  }
	
	if (screen_w < 974) {

	  $(".menu-item-has-children > a").after('<span class="sub-menu-toggler"><img class="menu-icon" src="/wp-content/themes/tpskeilaajat_2016/assets/images/menu-arrow.svg" /></span>');
	
	  $('.sub-menu-toggler').click(function () {
		  var parent = $(this).parent().attr("id");
		  $('#' + parent + " .sub-menu").toggleClass('show-me');
	  });
		
	}
	
    
  // $("body").fitVids();
  
	
	$(".achievement-pop-trigger").fancybox({
		// fitToView	: true,
		// autoSize	: true,
		// closeClick	: false,
		// openEffect	: 'none',
		// closeEffect	: 'none'
		
		width : 600,
	  height : 400,
	  autoSize : false
		
	});

	// https://localhost:3000/saavutukset/#trigger-for-perfect-henry-laine-282010

	$(window).load(function () {

	  var hash = window.location.hash;
	  
	  if (window.location.hash.indexOf('trigger-for-') == 1) { // not 0 because # is first character of window.location.hash
	    $(hash).fancybox().trigger('click');
	  }
	});

})(jQuery);
